import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Products from '../products/Products';
import Orders from '../orders/Orders';
import Margins from '../settings/Margins';
class Main extends Component {

    render() {
        return (
            <div>
                <Navbar />
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/products" />} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/orders" component={Orders} />
                    <Route exact path="/settings/margins" component={Margins} />
                </Switch>
            </div>
        );
    }
}

export default Main;
