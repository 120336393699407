import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImgsViewer from "react-images-viewer";

import UserService from "../../services/UserService";
import ProductService from "../../services/ProductService";
import Kichiri from "../../services/KichiriService";
import Dropdown from "../common/dropdown/Dropdown";
import * as Icon from 'react-feather';
import { withAlert } from 'react-alert';
import Modal from 'react-responsive-modal';
import moment from "moment";
import numeral from "numeral";
import queryString from "query-string";

import './products.css';

const arr = [];

class Products extends Component {

    constructor() {
        super();

        this.state = {
            products: [],
            categories: [],
            brands: [],
            search: '',
            loading: true,

            showMediaViewer: false,
            mediaIndex: 0,
            media: [],

            limit: 50,
            offset: 0,
            loadedAll: false,

            isModalOpen: false,
            currentIndex: 0,
            currentProduct: null,
            margin: 52,
            syncing: false,

            selected: [],

            synced: false
        };

        this.onSearch = this.onSearch.bind(this);
        this.onOpenMediaViewer = this.onOpenMediaViewer.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.selectSKU = this.selectSKU.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onMultiSync = this.onMultiSync.bind(this);
    }

    async componentDidMount() {
        await this.fetchProducts();
    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {
            this.fetchProducts();
        }
    }

    async fetchProducts() {
        let { search, limit, offset } = this.state;

        let products = [];
        let synced = false;

        if (this.props.location && this.props.location.search) {
			let params = queryString.parse(this.props.location.search);

			if (params.synced === "true") {
                synced = true;
                products = await ProductService.getProducts(limit, offset, search, true);
			} else {
                products = await ProductService.getProducts(limit, offset, search);
            }
		} else {
            products = await ProductService.getProducts(limit, offset, search);
        }

        if (!products) {
            // Error occurred;
            return;
        }

        this.setState({
            synced,
            products,
            loading: false,
            loadedAll: products.length < limit
        });
    }

    onChange(event) {
        let { name, value } = event.target


        if (name === "margin") {
            if (isNaN(value) || value > 100 || value < 0) {
                return;
            }
        }

        this.setState({
            [name]: value
        })
    }

    onSearch(event) {
        this.setState({
            limit: 50,
            search: event.target.value
        }, async () => {
            await this.fetchProducts();
        });
    }

    // onCategorySelect(partner) {
    //     this.setState({
    //         partnerId: partner.key
    //     }, async () => {
    //         await this.fetchProducts();
    //     })
    // }

    onOpenMediaViewer(product, index = 0) {
        let { sku, name, mediaHost, media } = product;

        let items = media.split(",");

        let finalMedia = items.map((item) => {

            let _mediaHost = `https://storage.googleapis.com/${mediaHost.substring(7, mediaHost.length)}`;

            return {
                src: `${_mediaHost}${item}`,
                caption: `SKU: ${sku} -- Name: ${name}`
            }
        });

        this.setState({
            media: finalMedia, showMediaViewer: true, mediaIndex: index
        });
    }

    async openModal(currentProduct, index) {
        this.setState({
            currentProduct, isModalOpen: true, currentIndex: index
        })
    }

    async onModalClose() {
        this.setState({
            isModalOpen: false
        })
    }

    async onSyncProduct(product, index) {
        let { syncing, margin } = this.state;
        let { alert } = this.props;
        let { sku } = product;

        if (syncing || product.synced) {
            return;
        }

        this.setState({
            syncing: true
        });

        // let loadingAlert = alert.info(`Syncing ... ${sku} with Shopify.`, {
        //     timeout: 60000
        // });

        let success = await ProductService.syncProduct(sku, margin);

        if (!success) {
            alert.error(`Error occurred trying to sync ${sku} with Shopify.`);
            return;
        }

        let { products } = this.state;
        products[index].synced = true;
        this.setState({ products, syncing: false });

        // alert.remove(loadingAlert);

        // setTimeout(() => {
        alert.success(`Successfully synced ${sku} with Shopify.`);
        // }, 100);
    }

    onLoadMore() {
        let { limit, loadedAll } = this.state;

        if (loadedAll) {
            return;
        }

        this.setState({
            limit: limit + 50
        }, () => {
            this.fetchProducts();
        })
    }

    renderProductImages(product) {
        let { mediaHost, media } = product;
        let items = media.split(",");

        let _mediaHost = `https://storage.googleapis.com/${mediaHost.substring(7, mediaHost.length)}`;
        return items.map((item, index) => {
            return <div onClick={() => this.onOpenMediaViewer(product, index)} className="product-details-media-item"><img className="product-details-media-item-img" src={`${_mediaHost}${item}`}/></div>
        });
    }

    async onMultiSync() {
        let { alert } = this.props;
        let { selected } = this.state;

        if (selected.length === 0){
            alert.info(`No products have been selected for syncing.`);
            return;
        }

        let success = await ProductService.multiSync(selected);

        if (!success) {
            alert.error(`An error occurred trying to multi sync.`);
            return;
        }

        alert.success(`Successfully queued products for syncing.`)
    }

    onSelectAll(sku, index) {
        let newSelected = this.state.selected.slice(0);

        if (this.state.products.length == newSelected.length) {
            newSelected = [];
        } else {
            newSelected = this.state.products.map((product) => {
                return product.sku;
            });
        }

        this.setState({
            selected: newSelected
        });
    }

    selectSKU(sku, index) {
        let newSelected = this.state.selected.slice(0);

        if ( newSelected.includes(sku) ) {
            newSelected.splice(newSelected.indexOf(sku), 1);
        } else {
            newSelected.push(sku);
        }

        this.setState({
            selected: newSelected
        });

    }

    onCatalogUpdate = async () => {

        let { alert } = this.props;
        let success = await ProductService.runCatalogUpdate();

        if (!success) {
            alert.error(`An error occurred trying to multi sync.`);
            return;
        }

        alert.success(`Successfully fetching latest products from UpTeam`)
    }

    render() {
        let { products, categories, brands, loading, search, showMediaViewer, mediaIndex, media, loadedAll, isModalOpen, currentProduct, margin, currentIndex, syncing, selected, synced } = this.state;

        return (
            <div className="container">

                <div className="header">
                    <div className="header-title">
                        <h1 className="header-title-text">Products {synced ? "(Synced)" : ""}</h1>
                        
                    </div>

                    <div className="header-options-container">
                        {/*}<div className="clients-hdr-option-1">
                            <Dropdown
                                items={categories}
                                onSelect={this.onCategorySelect}
                            />
                        </div>
                        <div className="clients-hdr-option-1">
                            <Dropdown
                                items={brands}
                                onSelect={this.onBrandSelect}
                            />
                        </div>*/}
                        {/* <div className="header-option">
                            <div className={`button button--header`} onClick={this.onCatalogUpdate}>UpTeam Fetch</div>
                        </div> */}
                        <div className="header-option">
                            <div className={`button button--header ${selected.length === 0 ? 'button--inactive' : ''}`} onClick={this.onMultiSync}>Multi Sync</div>
                        </div>
                        <div className="header-option">
                            <input className="search-input" type="search" name="search" placeholder="Search..." autoComplete="off" value={search}  onChange={this.onSearch} />
                        </div>
                    </div>
                </div>

                {loading && (<div className="loader">
                    Loading ...
                </div>)}

                {products.length === 0 && !loading && ((<div className="no-items">
                    <div>
                        <Icon.Frown size={128} />
                    </div>
                    <div>
                        ... no products ...
                    </div>
                </div>))}

                {products.length > 0 && !loading && (<div className="products-container">
                    <div className="products-header products-item">
                        <div className="products-item-select"><input type="checkbox" onChange={this.onSelectAll}></input></div>
                        <div className="products-item-sku">SKU</div>
                        <div className="products-item-name">Name</div>
                        <div className="products-item-description">Description</div>
                        <div className="products-item-brand">Brand</div>
                        <div className="products-item-rating">Rating</div>
                        <div className="products-item-rating-code">Rating Code</div>
                        <div className="products-item-price">Price (USD)</div>
                        <div className="products-item-stock">Stock</div>
                        <div className="products-item-actions">Synced</div>

                    </div>
                    {products.map((product, index) => {

                        let odd = index % 2 === 0;

                        return <div className={`products-item ${odd ? "products-item--stripe" : ""}`} key={index}>
                            <div className="products-item-select"><input type="checkbox" checked={selected.includes(product.sku)} onChange={() => this.selectSKU(product.sku, index)}></input></div>
                            <div className="products-item-sku">{product.sku}</div>
                            <div onClick={() => this.openModal(product, index)} className="products-item-name">{product.name}</div>
                            <div className="products-item-description">{product.description}</div>
                            <div className="products-item-brand">{product.brand}</div>
                            <div className="products-item-rating">{product.rating}</div>
                            <div className="products-item-rating-code">{product.ratingCode}</div>
                            <div className="products-item-price">{numeral(product.price).format('$ 0,0.00')}</div>

                            <div className="products-item-stock">{product.stock}</div>
                            <div className="products-item-actions">
                                <div>{product.syncing ? <Icon.RefreshCw size={16} /> : (product.synced ? <div><Icon.Check size={16} /> {moment(product.synced_at).format("YYYY-MM-DD @ hh:mm A")} </div>  : <div />)}</div>
                            </div>
                        </div>
                    })}
                    {!loadedAll && <div className="load-more-button" onClick={this.onLoadMore}>Load More</div>}

                </div>)}

                <Modal open={isModalOpen} onClose={this.onModalClose} center>
                    {currentProduct && <div className="product-details">
                        <div className="product-details-name">{currentProduct.name} - {currentProduct.sku}</div>
                        <div className="product-details-brand">{currentProduct.brand}</div>
                        <div className="product-details-description">{currentProduct.description}</div>
                        <div className="product-details-media">{this.renderProductImages(currentProduct)}</div>
                        <div className="product-details-price-field">
                            <div className="product-details-price-field-label">Markup</div>
                            <input className="product-details-price-field-input" onChange={this.onChange} value={margin} name="margin"/>%
                        </div>
                        <div className="product-details-price-field">
                            <div className="product-details-price-field-label">UpTeam Price</div>
                            <div>{numeral(currentProduct.price).format('$ 0,0.00')}</div>
                        </div>
                        <div className="product-details-price-field">
                            <div className="product-details-price-field-label">With Markup</div>
                            <div>{numeral(ProductService.calculateWithMargin(currentProduct.price, margin)).format('$ 0,0.00')}</div>
                        </div>
                        <div className="product-details-price-field">
                            <div className="product-details-price-field-label">Recommended Shopify Price</div>
                            <div>{numeral(ProductService.calculateFinalPrice(currentProduct.price, margin)).format('$ 0,0.00')}</div>
                        </div>
                        <div className="button" onClick={() => this.onSyncProduct(currentProduct, currentIndex)}>
                            {syncing && <div>Syncing ...</div>}
                            {!syncing && !currentProduct.synced && <>
                                <div>Sync this product</div>
                                <div className="icon"><Icon.RefreshCw size={16} /></div>
                            </>}
                            {!syncing && currentProduct.synced && <>
                                <div>Synced with Shopify</div>
                                <div className="icon"><Icon.Check size={16} /></div>
                            </>}
                        </div>
                    </div>}
                </Modal>

                <ImgsViewer
                    closeBtnTitle="Close"
                    leftArrowTitle="Left"
                    rightArrowTitle="Right"
                    currImg={mediaIndex}
                    imgs={media}
                    isOpen={showMediaViewer}
                    onClickPrev={() => {
                        this.setState({ mediaIndex: mediaIndex - 1 });
                    }}
                    onClickNext={() => {
                        this.setState({ mediaIndex: mediaIndex + 1 });
                    }}
                    onClose={() => {
                        this.setState({ showMediaViewer: false });
                    }}
                />
            </div>
        );
    }
}

export default withAlert(Products)
