import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/auth/Login.jsx';
import PrivateRoute from './components/common/PrivateRoute.jsx';
import Main from './components/layout/Main';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import './App.css';

// optional cofiguration
const options = {
    position: 'bottom center',
    timeout: 5000,
    offset: '30px',
    transition: 'fade',
    containerStyle: {
        zIndex: 9999
    }
}

class App extends Component {
    render() {
        return (
            <AlertProvider template={AlertTemplate} {...options}>
                <Router>
                    <div className="App">
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <PrivateRoute component={Main} path="/" />
                        </Switch>
                    </div>
                </Router>
            </AlertProvider>
        );
    }
}

export default App;
