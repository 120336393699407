import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import './login.css';

import { withAlert } from 'react-alert';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            name: '',
            loading: false
        };

        this.onChange = this.onChange.bind(this);
        this.signIn = this.signIn.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    onEnter(e) {
        if(e.keyCode === 13) {
            e.preventDefault();
            this.signIn();
        }
    }

    async signIn() {

        this.setState({
            loading: true
        });

        try {
            let response = await Kichiri.user.login({
                email: this.state.email,
                password: this.state.password
            });

            UserService.storeUserData(response.data);
            let userData = UserService.getUserData();

            console.log(userData);

            this.setState({
                loading: false,
                name: userData.firstName
            });

            this.props.history.push('/products');

            this.props.alert.success('Welcome ' + this.state.name + ' !');

        } catch (error) {
            this.props.alert.error('Invalid username or password');
            console.log(error);
        }

    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        let userData = UserService.getUserData();

        if (userData) {
            return <Redirect to={"/products"} />
        }

        return (
            <div className="login-container">

                <form>
                    <div className="login-logo-container">
                        <img className="login-logo" src="/connectshopio.svg" alt=""></img>
                    </div>
                    <div>
                        <input
                            className="login-email-field"
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.onChange}
                            autoComplete="off"
                            />
                    </div>
                    <div>
                        <input
                            className="login-password-field"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.onChange}
                            onKeyDown={this.onEnter}
                            autoComplete="off"
                            />
                    </div>
                    <div>
                      <button className="sign-in-button" onClick={this.signIn} type="button" >
                          Sign In
                      </button>
                    </div>
                </form>

            </div>
        );
    }
}

export default withAlert(Login);
