import React, { Component } from 'react';
// import WORKFLOW from "../../../constants/Workflow";
import PropTypes from "prop-types";

class Dropdown extends Component {

    constructor(props){
        super(props);

        this.state = {
            show: false,
            items: [],
            selected: '',
            displayTextColor: '',
            classChange: "status-dropdown-display-container"
        };

        this.inlineSelect = null;

        this.node = null;
        this.onSelect = this.onSelect.bind(this);
        this.alternateRender = this.alternateRender.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    };

    componentDidMount() {

        let item = this.getItem();

        this.setState({
            items: this.props.items || [],
            key: item.key,
            selected: this.props.selected,
            display: item.value || ""
        });

        document.addEventListener('mousedown', this.handleClick, false);
    }

    handleClick(e) {
        if (!this.node) {
            return;
        }

        if (this.node.contains(e.target)) {
            return;
        }

        this.setState({
            show: false
        }, () => {
            this.setState({
                classChange: "status-dropdown-display-container"
            })
        })
    }

    getItem() {
        let selected = this.props.selected || 0;

        let item = this.props.items.find((item) => {
            return item.key === selected
        });

        if (!item) {
            item = this.props.items[0];
        }

        if (!item) {
            item = {};
        }

        return item;
    }

    componentDidUpdate(prevProps) {

        if (prevProps.items.length !== this.props.items.length){
            let item = this.getItem();
            this.setState({
                items: this.props.items,
                key: item.key,
                display: item.value,
                displayTextColor: item.color
            })
        }

        if (prevProps.selected !== this.props.selected) {
            let item  = this.getItem();
            this.setState({
                key: item.key,
                display: item.value,
                displayTextColor: item.color
            })
        }

    }

    toggleDropdown() {
        let { show } = this.state;

        if (this.props.disabled) {
            return;
        }

        this.setState({
            show: !show,
            classChange: show ? "status-dropdown-display-container" : "status-dropdown-display-change",
        });
    }

    onSelect(item) {
        this.setState({
            show: false,
            key: item.key,
            display: item.value,
            displayTextColor: item.color
        }, () => {
            this.setState({
                classChange: "status-dropdown-display-container"
            })
        });

        if (this.props.onSelect) {
            this.props.onSelect(item);
        }
    }

    onStatusSelect(event) {
        let { items } = this.state;
        let { metadata } = this.props;

        this.setState({
            show: false,
            key: event.target.value
        });

        let item = items.find((item) => {
            return item.key == event.target.value
        });

        if (this.props.onSelect) {
            this.props.onSelect(item, metadata);
        }
    }

    inLineStatusRender() {
        return (
            <div ref={node => this.node = node} className="in-line-status-dropdown-container">
                {!this.state.show &&
                    <div onClick={this.toggleDropdown} className="in-line-status-dropdown" >
                        {this.state.display}
                    </div>
                }
                {this.state.show && this.state.items.length > 0 && (<select id="inline-select" ref={(ref) => this.inlineSelect = ref} className="in-line-select" onChange={(e) => this.onStatusSelect(e)} value={this.state.key}>
                {this.state.items.map((status) => {
                    return (
                        <option key={status.key} value={status.key} >
                            {status.value}
                        </option>
                    );
                })}
            </select>)}

        </div>)
    }

    statusRender() {
        return (<div ref={node => this.node = node} onClick={this.toggleDropdown} className="status-dropdown-container">

            <span className="dropdown-title">{this.props.title}</span>


            <div className={this.state.classChange} style={{ backgroundColor: this.state.displayTextColor, border: '1px solid #E4E6E6'}}>
                <div>{this.state.display}</div>
                <div className="dropdown-chevron">▼</div>
            </div>

            {this.state.show && this.state.items.length > 0 && (<ul className="status-dropdown-display">
                {this.state.items.map((item) => {
                    return (
                        <li key={item.key} onClick={() => this.onSelect(item)} className="status-dropdown-item" style={{ backgroundColor: "yellow" }}>
                            <span className="status-dropdown-item-option">
                                {item.value}
                            </span>
                        </li>
                    );
                })}
            </ul>)}
        </div>)
    }

    alternateRender() {
        return (
            <div ref={node => this.node = node} onClick={this.toggleDropdown} className="dropdown-alternate-container">

                <span className="dropdown-title">{this.props.title}</span>

                <div className={ this.props.className ? this.props.className + " " + this.state.classChange : this.state.classChange }>
                    <div>{this.state.display}</div>
                    <div className="dropdown-chevron">▼</div>
                </div>
                {this.state.show && this.state.items.length > 0 && (<ul className="alternate-dropdown-display">
                    {this.state.items.map((item) => {
                        return (
                            <li className="alternate-dropdown-item" key={item.key} onClick={() => this.onSelect(item)}>
                                <span className="alternate-dropdown-item-option">
                                    {item.value}
                                </span>
                            </li>
                        );
                    })}
                </ul>)}

            </div>)
        }

    render() {
        if (this.props.alternate) {
            return this.alternateRender()
        }
        else if (this.props.status) {
            return this.statusRender()
        }
        else if (this.props.inLineStatus) {
            return this.inLineStatusRender()
        }

        return (<div ref={node => this.node = node} onClick={this.toggleDropdown} className="default-dropdown-container">
            <div className="default-dropdown-button">
                <div>{this.state.display}</div>
                <div className="default-dropdown-chevron">▼</div>
            </div>
            { this.state.show && this.state.items.length > 0 && (<ul className="default-dropdown-display">
                {this.state.items.map((item) => {
                    return (
                        <li className="default-dropdown-item" key={item.key} onClick={() => this.onSelect(item)}>
                            <span className="default-dropdown-item-option">
                                {item.value}
                            </span>
                        </li>
                    );
                })}
            </ul>)}

        </div>);
    }
}

Dropdown.propTypes = {
  title: PropTypes.string
};

export default Dropdown;
