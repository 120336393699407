import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImgsViewer from "react-images-viewer";

import UserService from "../../services/UserService";
import OrderService from "../../services/OrderService";
import Dropdown from "../common/dropdown/Dropdown";
import * as Icon from 'react-feather';
import { withAlert } from 'react-alert';
import Modal from 'react-responsive-modal';
import moment from "moment";
import numeral from "numeral";

import './orders.css';

class Orders extends Component {

    constructor() {
        super();

        this.state = {
            orders: [],
            search: '',
            loading: true,

            showMediaViewer: false,
            mediaIndex: 0,
            media: [],

            limit: 50,
            offset: 0,
            loadedAll: false,

            isModalOpen: false,
            currentIndex: 0,
            currentOrder: null,
            margin: 30
        };

        this.onSearch = this.onSearch.bind(this);
        this.onOpenMediaViewer = this.onOpenMediaViewer.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
    }

    async componentDidMount() {
        await this.fetchOrders();
    }

    async fetchOrders() {
        let { search, limit, offset } = this.state;

        let orders = await OrderService.getOrders(limit, offset, search);

        if (!orders) {
            // Error occurred;
            return;
        }

        this.setState({
            orders,
            loading: false,
            loadedAll: orders.length < limit
        });
    }

    onChange(event) {
        let { name, value } = event.target

        if (name === "margin") {
            if (isNaN(value) || value > 100 || value < 0) {
                return;
            }
        }

        this.setState({
            [name]: value
        })
    }

    onSearch(event) {
        this.setState({
            limit: 50,
            search: event.target.value
        }, async () => {
            await this.fetchOrders();
        });
    }

    // onCategorySelect(partner) {
    //     this.setState({
    //         partnerId: partner.key
    //     }, async () => {
    //         await this.fetchProducts();
    //     })
    // }

    onOpenMediaViewer(product, index = 0) {
        let { sku, name, mediaHost, media } = product;

        let items = media.split(",");

        let finalMedia = items.map((item) => {
            return {
                src: `${mediaHost}${item}`,
                caption: `SKU: ${sku} -- Name: ${name}`
            }
        });

        this.setState({
            media: finalMedia, showMediaViewer: true, mediaIndex: index
        });
    }

    async openModal(currentOrder, index) {
        this.setState({
            currentOrder, isModalOpen: true, currentIndex: index
        })
    }

    async onModalClose() {
        this.setState({
            isModalOpen: false
        })
    }

    onLoadMore() {
        let { limit, loadedAll } = this.state;

        if (loadedAll) {
            return;
        }

        this.setState({
            limit: limit + 50
        }, () => {
            this.fetchOrders();
        })
    }

    renderProductImages(product) {
        let { mediaHost, media } = product;
        let items = media.split(",");
        return items.map((item, index) => {
            return <div onClick={() => this.onOpenMediaViewer(product, index)} className="product-details-media-item"><img className="product-details-media-item-img" src={`${mediaHost}${item}`}/></div>
        });
    }

    renderOrderItems(order) {
        let orders = order.orders;

        return orders.length > 0 && (<div className="orders-container order-details-container">
            <div className="orders-header orders-item">
                <div className="order-item-sku">SKU</div>
                <div className="order-item-sku">Shopify ID</div>
                <div className="order-item-sku">UpTeam ID</div>
                <div className="order-item-sku">Status</div>
            </div>
            {order.orders.map((orderItem, index) => {

                let odd = index % 2 === 0;

                return <div className={`orders-item ${odd ? "orders-item--stripe" : ""}`}>
                    <div className="order-item-sku">{orderItem.sku}</div>
                    <div className="order-item-sku">{orderItem.shopify_id}</div>
                    <div className="order-item-sku">{orderItem.upteam_id}</div>
                    <div className="order-item-sku">{orderItem.status}</div>
                </div>
            })}
        </div>)
    }

    render() {
        let { orders, loading, search, showMediaViewer, mediaIndex, media, loadedAll, isModalOpen, currentOrder, margin, currentIndex } = this.state;

        return (
            <div className="container">

                <div className="header">
                    <div className="header-title">
                        <h1 className="header-title-text">Orders</h1>
                    </div>

                    <div className="header-options-container">
                        <div className="header-option">
                            <input className="search-input" type="search" name="search" placeholder="Search..." autoComplete="off" value={search}  onChange={this.onSearch} />
                        </div>
                    </div>
                </div>

                {loading && (<div className="loader">
                    Loading ...
                </div>)}

                {orders.length === 0 && !loading && ((<div className="no-items">
                    <div>
                        <Icon.Frown size={128} />
                    </div>
                    <div>
                        ... no orders ...
                    </div>
                </div>))}

                {orders.length > 0 && !loading && (<div className="orders-container">
                    <div className="orders-header orders-item">
                        <div className="orders-item-order-id">ID</div>
                        <div className="orders-item-skus">SKUs</div>
                        <div className="orders-item-shopify-order-id">Shopify Order ID</div>
                        <div className="orders-item-shopify-order-number">Shopify Order Number</div>
                        <div className="orders-item-upteam-purchase-order-id">UpTeam PO ID</div>
                        <div className="orders-item-upteam-reference">UpTeam Reference</div>
                        <div className="orders-item-status">Status</div>

                    </div>
                    {orders.map((order, index) => {

                        let odd = index % 2 === 0;

                        return <div className={`orders-item ${odd ? "orders-item--stripe" : ""}`} key={index}>
                            <div onClick={() => this.openModal(order, index)} className="orders-item-order-id">{order.id}</div>
                            <div className="orders-item-skus">{OrderService.getOrderSkus(order)}</div>
                            <div className="orders-item-shopify-order-id">{order.shopify_order_id}</div>
                            <div className="orders-item-shopify-order-number">{order.shopify_order_number}</div>
                            <div className="orders-item-upteam-purchase-order-id">{order.upteam_purchase_order_id}</div>
                            <div className="orders-item-upteam-reference">{order.upteam_reference}</div>
                            <div className="orders-item-status"><div className={`order-status order-status--${OrderService.getOrderStatus(order)}`}></div></div>
                        </div>
                    })}
                    {!loadedAll && <div className="load-more-button" onClick={this.onLoadMore}>Load More</div>}

                </div>)}

                <Modal open={isModalOpen} onClose={this.onModalClose} center>
                    {currentOrder && <div className="order-details">
                        <div className="order-details-name">Shopify Order ID - {currentOrder.shopify_order_id}</div>
                        <div className="order-details-field">Shopify Order Number - {currentOrder.shopify_order_number}</div>
                        <div className="order-details-field">UpTeam Purchase Order ID - {currentOrder.upteam_purchase_order_id}</div>
                        <div className="order-details-field">UpTeam Reference - {currentOrder.upteam_reference}</div>
                        <div className="order-details-field">Status <div className={`order-status order-status--${OrderService.getOrderStatus(currentOrder)}`}></div></div>
                        {this.renderOrderItems(currentOrder)}
                    </div>}
                </Modal>

                <ImgsViewer
                    closeBtnTitle="Close"
                    leftArrowTitle="Left"
                    rightArrowTitle="Right"
                    currImg={mediaIndex}
                    imgs={media}
                    isOpen={showMediaViewer}
                    onClickPrev={() => {
                        this.setState({ mediaIndex: mediaIndex - 1 });
                    }}
                    onClickNext={() => {
                        this.setState({ mediaIndex: mediaIndex + 1 });
                    }}
                    onClose={() => {
                        this.setState({ showMediaViewer: false });
                    }}
                />
            </div>
        );
    }
}

export default withAlert(Orders)
