export default `swagger: "2.0"
info:
  version: "0.0.1"
  title: dr-api
# during dev, should point to your local machine
host: localhost:4000
# basePath prefixes all resource paths
basePath: /api
#
schemes:
  # tip: remove http to make production-grade
  - http
# format of bodies a client can send (Content-Type)
consumes:
  - application/json
# format of the responses to the client (Accepts)
produces:
  - application/json

securityDefinitions:
  authorization:
    x-authorize: security/auth.js
    scopes:
      default: Default Scope

security:
  - authorization: [ default ]

tags:
  - name: user
    description: User namespace
  - name: product
    description: product namespace
  - name: order
    description: order namespace
  - name: webhooks
    description: webhooks namespace

paths:

  /user/login:
    post:
      description: Attempt to log the user in
      tags: [ user ]
      operationId: login

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/logout:
    post:
      description: Attempt to logout the user
      tags: [ user ]
      operationId: logout

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/create:
    post:
      description: Create a user
      tags: [ user ]
      operationId: create

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /user/{userId}/update:
    post:
      description: Update a user
      tags: [ user ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/list:
    get:
      description: List all the products
      tags: [ product ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/{sku}:
    get:
      description: Fetch a product
      tags: [ product ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/{sku}/update:
    post:
      description: Update a product
      tags: [ product ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/mass_sync:
    post:
      description: Syncs the upteam catalog
      tags: [ product ]
      operationId: massSync

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/multi_sync:
    post:
      description: Syncs the upteam catalog
      tags: [ product ]
      operationId: multiSync

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/manual_catalog_update:
    post:
      description: Syncs the upteam catalog
      tags: [ product ]
      operationId: manualCatalogUpdate

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/margin/list:
    get:
      description: List all the product margins for ConnectShop
      tags: [ product ]
      operationId: listMargins

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /product/margin/{marginId}/update:
    post:
      description: Update a product margin
      tags: [ product ]
      operationId: updateMargin

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /order/create:
    post:
      description: Create an order
      tags: [ order ]
      operationId: create

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /order/list:
    get:
      description: List all the orders
      tags: [ order ]
      operationId: list

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /order/{orderId}:
    get:
      description: Fetch a order
      tags: [ order ]
      operationId: get

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /order/{orderId}/update:
    post:
      description: Update a order
      tags: [ order ]
      operationId: update

      security: [
        authorization: [ default ]
      ]

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /webhooks/order_creation:
    post:
      description: Update a order
      tags: [ webhooks ]
      operationId: orderCreation

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

  /webhooks/product_deletion:
    post:
      description: Unsync a product
      tags: [ webhooks ]
      operationId: productDeletion

      security: []

      responses:
        "200":
          description: Success
          schema:
            $ref: "#/definitions/Response"
        default:
          description: Error
          schema:
            $ref: "#/definitions/Response"

definitions:

  Request:
    description: Basic Request

  Response:
    description: A standard error response for all API methods.
`