import React, { Component } from 'react';
import { withAlert } from 'react-alert';
import numeral from "numeral";
import ProductService from '../../services/ProductService';
import '../products/products.css';
import "./margins.css";

class Margins extends Component {

    constructor() {
        super();

        this.state = {
            margins: [],
            loading: true,
            saving: false,
            saved: false
        };

    }

    update(o) {
        return new Promise((resolve) => {
            this.setState(o, resolve);
        });
    }

    timeout = (ms) => {
        return new Promise(function (resolve, reject) {
            setTimeout(resolve, ms);
        });
    }

    async componentDidMount() {
        await this.fetchMargins();
    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {
            this.fetchMargins();
        }
    }

    async fetchMargins() {
        let margins = await ProductService.fetchMargins();

        console.log(margins);

        this.setState({
            loading: false,
            margins
        });
    }

    onChange = async (marginId, event) => {
        let { value } = event.target;
        let { margins } = this.state;
    
        await this.update({
            saving: true
        });

        for (let m of margins) {
            if (m.id == marginId) {
                m.margin = parseInt(value, 10);
            }
        }

        margins = JSON.parse(JSON.stringify(margins))

        await this.update({
            margins
        });

        await ProductService.updateMargin(marginId, value);

        await this.timeout(2000);

        await this.update({
            saving: false,
            saved: true
        })

        await this.timeout(2000);

        await this.update({
            saved: false
        })
    }

    render() {
        let { loading, margins, saving, saved } = this.state;

        return (
            <div className="container">

                <div className="header">
                    <div className="header-title">
                        <h1 className="header-title-text">Product Margins</h1>
                        
                    </div>
                </div>

                {loading && (<div className="loader">
                    Loading ...
                </div>)}

                {!loading && <div className="margins">
                    {margins.map((m) => {
                        return <div className="margin-item">
                            <div className="margin-item__block">Start {numeral(m.start).format('$ 0,0.00')}</div>
                            <div className="margin-item__block">End {numeral(m.end).format('$ 0,0.00')}</div>
                            <input className="product-details-price-field-input" onChange={(value) => this.onChange(m.id, value)} value={m.margin} name="margin"/>%
                        </div>
                    })}    
                </div>}

                <div className="margin-status">
                    {saving && <div>Saving...</div>}
                    {saved && <div>Saved!</div>}
                </div>


            </div>
        );
    }
}

export default withAlert(Margins)
