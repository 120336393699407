import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserService from '../../services/UserService';

const PrivateRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
    {...rest}
    render={props =>
      UserService.getAuthToken() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />)
}


export default PrivateRoute;
