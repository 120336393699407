import Kichiri from "./KichiriService";
import UserService from "./UserService";

const OrderService = {

    /**
     * Returns a list of Orders
     *
     * @param {Number} [limit=50]
     * @param {Number} [offset=0]
     * @param {String} [search=null]
     * @return {Promise}
     */
    async getOrders(limit = 50, offset = 0, search = null) {
        let user = UserService.getUserData();

        let request = {
            limit, offset
        }

        if (search) {
            request.search = search;
        }

        try {
            let response = await Kichiri.order.list({ }, request, user.authToken);

            return response.data;
        } catch (error) {
            console.log(error);
        }

        return null;
    },

    getOrderSkus(order) {
        return order.orders.map(order => order.sku).join(", ");
    },

    getOrderStatus(order) {

        let orderItems = order.orders;

        let sold = 0

        for (let oi of orderItems) {
            sold += oi.status === "sold" ? 1 : 0;
        }

        if (sold === 0) {
            return "red";
        } else if (sold < orderItems.length) {
            return "yellow";
        } else if (sold === orderItems.length) {
            return "green";
        }

    }
}

export default OrderService;
