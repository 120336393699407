import Kichiri from "./KichiriService";
import UserService from "./UserService";

const ProductService = {

    /**
     * Returns a list of products available in UpTeam
     *
     * @param {Number} page
     * @return {Promise}
     */
    async getProducts(limit = 50, offset = 0, search = null, synced = false) {
        let user = UserService.getUserData();

        let request = {
            limit, offset, synced
        }

        if (search) {
            request.search = search;
        }

        try {
            let response = await Kichiri.product.list({ }, request, user.authToken);

            return response.data;
        } catch (error) {
            console.log(error);
        }

        return null;
    },

    /**
     * Sync the product
     *
     * @param {String} sku
     * @return {Promise}
     */
    async syncProduct(sku, margin) {
        let user = UserService.getUserData();

        let request = {
            sku, margin, sync: true
        };

        try {
            let response = await Kichiri.product.update(request, {}, user.authToken);

            return response.data;
        } catch (error) {
            console.log(error);
        }

        return null;
    },

    /**
     * Syncs multiple skus from Upteam to Shopify, the call is passive
     *
     * @param {Array} skus
     * @return {Promise}
     */
    async multiSync(skus) {
        let user = UserService.getUserData();

        let request = {
            skus
        }

        try {
            let response = await Kichiri.product.multiSync(request, {}, user.authToken);

            return response.data
        } catch (error) {
            console.log(error);
        }

        return null;
    },

    /**
     * [calculateWithMargin description]
     * @param  {[type]} price        [description]
     * @param  {Number}
     * @return {[type]}              [description]
     */
    calculateWithMargin(price, margin) {
        margin = margin / 100;
        let modifier = margin + 1;
        return (price * modifier).toFixed(2);
    },

    /**
     * Applies a global percentage margin and round the value up the nearest *9.99
     *
     * @param {Number} price
     * @return {Number}
     */
    calculateFinalPrice(price, margin) {
        margin = margin / 100;
        let modifier = margin + 1;
        return ((Math.ceil(Math.floor((price * modifier)) / 10) * 10) - 1) + .99;
    },

    async runCatalogUpdate() {
        let user = UserService.getUserData();

        try {
            let response = await Kichiri.product.manualCatalogUpdate({}, {}, user.authToken);

            return response.data
        } catch (error) {
            console.log(error);
        }

        return null;
    },

    async fetchMargins() {
        let user = UserService.getUserData();

        try {
            let response = await Kichiri.product.listMargins({}, {}, user.authToken);

            return response.data
        } catch (error) {
            console.log(error);
        }

        return null;
    },

    async updateMargin(marginId, percentage) {
        let user = UserService.getUserData();

        try {
            let response = await Kichiri.product.updateMargin({ marginId, percentage }, {}, user.authToken);

            return response.data
        } catch (error) {
            console.log(error);
        }

        return null;


    }
}

export default ProductService;
