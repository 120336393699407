import Kichiri from "./KichiriService";
const USER_KEY = "dr_user"

var currentSetup = {
	userId: null,
	locationId: null,
	companyId: null,
	isSuperAdmin: false
}

const UserService = {

	/**
	 * Stores user data to the AsyncStorage
	 *
	 * @param {UserResponse} userData
	 * @return {Promise}
	 */
	storeUserData: (userData) => {
		try {
			let userDataString = JSON.stringify(userData);
		    localStorage.setItem(USER_KEY, JSON.stringify(userData));
			return userDataString;
		}
		catch (error) {
			console.log(error);
		}
	},

	/**
	 * Clears the user data from AsyncStorage
	 *
	 * @return {Promise}
	 */
	clearUserData: () => {
		try {
			localStorage.removeItem(USER_KEY);
			return;
		}
		catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches only relevant user data for the app
	 *
	 * @return {Promise}
	 */
	getUserData: () => {

		try {
			let data = localStorage.getItem(USER_KEY);
			data = JSON.parse(data);

			if (!data) {
				return null;
			}

			let userId = currentSetup.userId || data.id;

			return {
				userId: userId,
				firstName: data.first_name,
				lastName: data.last_name,
				authToken: data.auth_token,
				isSuperAdmin: false
			}
		} catch (error) {
			console.log(error);
		}

        return null;

	},

	/**
	 * Sets the current location and company ids for switching between locations and companies
	 *
	 * @param {Number} companyId
	 * @param {String} companyName
	 * @param {Number} locationId
	 * @param {String} locationName
	 */
	setUserCompanyAndLocation: (companyId, companyName) => {
		currentSetup.companyId = companyId;
		currentSetup.companyName = companyName || "";
	},

	/**
	 * Fetches all the user data for the current session, should be used with caution
	 *
	 * @return {Promise}
	 */
	getFullUserData: () => {
		try {
			let data = localStorage.getItem(USER_KEY);
			data = JSON.parse(data);

			return data;

		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Check to see if the user is already logged in
	 *
	 * @return {Promise}
	 */
	getAuthToken: () => {
		let data = null;
		try {
			data = localStorage.getItem(USER_KEY);
		}
		catch (error) {
			console.log(error);
		}

		return data;
	},

	/**
	 * Fetches a user via the userId
	 *
	 * @param {Number} userId
	 * @return {Promise}
	 */
	async fetchUser(userId) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.user.get({ userId }, {}, userData.authToken);

			console.log(response);

			return response.data
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches all the partner users
	 *
	 * @param {String}
	 * @return {Promise}
	 */
	async fetchPartners(defaultValue = "All Partners") {
		let partners = await this.fetchUsers(1);

		partners.unshift({
			key: 0,
			value: defaultValue
		})

		return partners;
	},


	/**
	 * Fetches all the users for a company
	 *
	 * @param  {String}
	 * @return {Promise}
	 */
	async fetchAssignableUsers(defaultValue = "Unassigned") {
		let partners = await this.fetchUsers();

		partners.unshift({
			key: 0,
			value: defaultValue
		})

		return partners;
	},

	/**
	 * Fetches the users from from the db
	 *
	 * @return {Array}
	 */
	async fetchUsers(groupId = 0) {
		let userData = this.getUserData();

		try {
			let response = await Kichiri.user.list({}, {
				companyId: userData.companyId,
				groupId: groupId,
			}, userData.authToken);

			let parsedResults = response.data.map((user) => {
				return {
					key: user.id,
					value: `${user.first_name} ${user.last_name}`
				}
			});

			return parsedResults;

		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Searches all the users in the given list
	 *
	 * @param {String} searchTerm
	 * @param {Array} users
	 * @return {Array}
	 */
	searchUsers(searchTerm, users) {

		try {
			searchTerm = searchTerm.toLowerCase();
			return users.filter((user) => {
				return user.first_name.toLowerCase().indexOf(searchTerm) !== -1 ||
				user.last_name.toLowerCase().indexOf(searchTerm) !== -1 ||
				user.email.toLowerCase().indexOf(searchTerm) !== -1
			})
		} catch (error) {
			console.log("An error occurred in searching users.");
		}

		return users;
	},

	/**
	 * Indicates whether the current user is an administrator
	 *
	 * @return {Boolean}
	 */
	isAdministrator() {
		let userData = this.getUserData();
		return userData.groupId === 1 || userData.groupId === 3;
	}
}

export default UserService;
