import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import './navbar.css';

class Navbar extends Component {

    constructor() {
        super();
        this.state = {
            loading: false
        };

        this.signOut = this.signOut.bind(this);
    }

    async signOut() {

        let userData = UserService.getUserData();

        this.setState({
            loading: true
        });

        try {
            let response = await Kichiri.user.logout({}, {}, userData.authToken);
            UserService.clearUserData();
            console.log(response);
        } catch (error) {
            console.log(error);
        }

        this.setState({
            loading: false
        });

    }

    componentDidMount() {

    }

    render() {
        let userData = UserService.getUserData();

        if (!userData) {
            return <Redirect to={"/login"} />
        }

        return (
            <div className="side-navbar-container">
                <div className="side-navbar-items">
                    <div className="navbar-item">
                        <h2 className="navbar-item-title">ConnectShop.io</h2>
                    </div>
                    <div className="navbar-item">
                        <h2 className="navbar-item-title">UpTeam</h2>
                        <Link className="navbar-link" to="/products">Products</Link>
                        <Link className="navbar-link" to="/orders">Orders</Link>
                        <Link className="navbar-link" to="/products?synced=true">Synced</Link>
                    </div>
                    <hr className="navbar-line-breaker"></hr>
                    <div className="navbar-item">
                        <h2 className="navbar-item-title">Settings</h2>
                        <Link className="navbar-link" to={`/settings/margins`}>Margins</Link>
                        {/* <Link className="navbar-link" to={`/settings/users/${userData.userId}`}>Profile</Link> */}
                        <span className="navbar-link" onClick={this.signOut}>Sign Out</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
